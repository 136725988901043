jQuery(function ($) {
    $( document ).ready(function() {

      $('.mobile-nav-btn').on('click',function(){
        $('body').toggleClass('open');
        $('.main-menu').toggleClass('open');
      });

      $(".scroll-down").click(function() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".scroll-to").offset().top
        }, 1000);
      });

      $('.video-btn').on('click', function(){
        var target = $(this).data('target');
        var origsrc = $(this).data('vidsrc');
        $(target+" iframe").attr('src','');
        //modify source to autoplay and start video
        $(target+" iframe").attr('src',origsrc + "&autoplay=1");
      });

      $('.video-close').on('click', function(){
        var target = $(this).data('target');
        var origsrc = $(this).data('vidsrc');
        $(target+" iframe").attr('src','');
        //modify source to autoplay and start video
        $(target+" iframe").attr('src',origsrc + "&autoplay=0");
      });

      $('.main-menu .hassub .sub-menu').each(function(){
        $btn = '<button class="opensub"><svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.353553 0.864868L4.82494 5.33626" stroke="white"/><path d="M4.17409 5.29547L8.47504 0.659912" stroke="white"/></svg></button>';
        $(this).before($btn);
      });

      $('.opensub').on('click',function(){
        $('.hassub').each(function(){
          $(this).removeClass('open');
        });
        $(this).closest('.hassub').addClass('open');
      });

      var lightbox = $('.gallery a').simpleLightbox();
      

  });
});

window.onscroll = function() {myFunction()};
var body = document.getElementsByTagName("BODY")[0];
var sticky = body.offsetTop;
function myFunction() {
  if (window.pageYOffset > sticky) {
    body.classList.add("sticky");
  } else {
    body.classList.remove("sticky");
  }
}